.Button__Base {
  @apply font-display relative w-full items-center justify-center rounded-sm border border-transparent px-4 py-2 text-center font-bold uppercase shadow-sm hover:opacity-75 focus:outline-none focus:ring-offset-gray-800 md:focus:ring-2 md:focus:ring-offset-2;
}

.Button {
  composes: Button__Base;
  @apply inline-flex text-sm;
}

.Button__Full {
  composes: Button__Base;
  @apply flex;
}

.Loader {
  height: 2px;
  @apply absolute inset-x-0 top-0 flex w-full items-start justify-start;
}

.Loader__Inner {
  height: 2px;
  @apply bg-black;
}
